/* App.svelte generated by Svelte v3.12.1 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	destroy_each,
	detach,
	element,
	empty,
	handle_promise,
	init,
	insert,
	noop,
	safe_not_equal,
	space,
	text
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = 'svelte-btjmy0-style';
	style.textContent = ".svelte-btjmy0{font-family:Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif}pre.svelte-btjmy0{font-family:monospace}a.news-item.svelte-btjmy0{display:block;padding:15px 10px;border-bottom:1px solid #eee;text-decoration:none;color:#000}.item-title.svelte-btjmy0{color:#2D6DF8}.date-string.svelte-btjmy0{color:#888;margin-top:5px;font-size:0.8em}a.news-item.svelte-btjmy0:hover .item-title.svelte-btjmy0{opacity:0.5}a.news-item.svelte-btjmy0:visited .item-title.svelte-btjmy0{color:#eee}a.news-item.svelte-btjmy0:visited .date-string.svelte-btjmy0{color:#eee}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = Object.create(ctx);
	child_ctx.item = list[i];
	child_ctx.i = i;
	return child_ctx;
}

// (67:0) {:catch error}
function create_catch_block(ctx) {
	var pre;

	return {
		c() {
			pre = element("pre");
			pre.textContent = "Hello! Welcome to QuickRSS!\n\nIn case you do not know, QuickRSS is an easy to use Web application\nhelp you quickly read any RSS feeds right on the browser.\n\nTo use, simply put the RSS feed URL in the query param, for example:\n\n    https://quickrss.now.sh/?url=https://news.ycombinator.com/rss\n\nYou can even put multiple RSS feeds, each one separated by a comma:\n\n    https://quickrss.now.sh/?url=feed-1,feed-2,feed-3\n\nTo avoid heavy load on the server, we saved the RSS data in your\nbrowser's localStorage for 5 minutes.";
			attr(pre, "class", "svelte-btjmy0");
		},

		m(target, anchor) {
			insert(target, pre, anchor);
		},

		p: noop,

		d(detaching) {
			if (detaching) {
				detach(pre);
			}
		}
	};
}

// (58:0) {:then feeds}
function create_then_block(ctx) {
	var div;

	let each_value = ctx.feeds;

	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
			attr(div, "class", "container svelte-btjmy0");
		},

		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},

		p(changed, ctx) {
			if (changed.loader || changed.formatDate || changed.getHost) {
				each_value = ctx.feeds;

				let i;
				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(changed, child_ctx);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}
				each_blocks.length = each_value.length;
			}
		},

		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (60:2) {#each feeds as item, i}
function create_each_block(ctx) {
	var a, div0, t0_value = ctx.item.title + "", t0, t1, div1, t2_value = ctx.getHost(ctx.item.link) + "", t2, t3, t4_value = ctx.formatDate(ctx.item.date) + "", t4, t5;

	return {
		c() {
			a = element("a");
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			t2 = text(t2_value);
			t3 = text(" | ");
			t4 = text(t4_value);
			t5 = space();
			attr(div0, "class", "item-title svelte-btjmy0");
			attr(div1, "class", "date-string svelte-btjmy0");
			attr(a, "class", "news-item svelte-btjmy0");
			attr(a, "target", "_blank");
			attr(a, "rel", "noreferrer noopener");
			attr(a, "href", ctx.item.link);
		},

		m(target, anchor) {
			insert(target, a, anchor);
			append(a, div0);
			append(div0, t0);
			append(a, t1);
			append(a, div1);
			append(div1, t2);
			append(div1, t3);
			append(div1, t4);
			append(a, t5);
		},

		p: noop,

		d(detaching) {
			if (detaching) {
				detach(a);
			}
		}
	};
}

// (56:15)  <p>Loading...</p> {:then feeds}
function create_pending_block(ctx) {
	var p;

	return {
		c() {
			p = element("p");
			p.textContent = "Loading...";
			attr(p, "class", "svelte-btjmy0");
		},

		m(target, anchor) {
			insert(target, p, anchor);
		},

		p: noop,

		d(detaching) {
			if (detaching) {
				detach(p);
			}
		}
	};
}

function create_fragment(ctx) {
	var await_block_anchor, promise;

	let info = {
		ctx,
		current: null,
		token: null,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 'feeds',
		error: 'error'
	};

	handle_promise(promise = ctx.loader, info);

	return {
		c() {
			await_block_anchor = empty();

			info.block.c();
		},

		m(target, anchor) {
			insert(target, await_block_anchor, anchor);

			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
		},

		p(changed, new_ctx) {
			ctx = new_ctx;
			info.block.p(changed, assign(assign({}, ctx), info.resolved));
		},

		i: noop,
		o: noop,

		d(detaching) {
			if (detaching) {
				detach(await_block_anchor);
			}

			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

function instance($$self) {
	// Sat, 28 Sep 2019 21:38:13 +0000
  const formatDate = dateString => {
    const parsed = new Date(dateString);
    return `${parsed.toLocaleDateString()} ${parsed.toLocaleTimeString()}`;
  }

  const getHost = url => new URL(url).hostname;

  const getNews = async param => {
    if (param) {
      const res = await fetch(`https://rss-worker.snackylab.workers.dev/${param}`);
      const data = await res.json();
      return memozie(param, true, data.items);
    } else {
      throw new Error();
    }
  };

  const param = window.location.search;

  const encode = obj => JSON.stringify(obj);
  const decode = str => JSON.parse(str);

  const memozie = (param, write, data) => {
    if (window.localStorage && btoa) {
      const key = btoa(param);
      if (!write) { // read mode
        const saved = window.localStorage.getItem(key);
        if (saved) {
          const found = decode(saved);
          if (found) {
            if (Date.now() - found.time <= 5 * 60 * 1000) { // Cache for 5 min
              console.log("Found cached data.");
              return found.data;
            } else {
              console.log("Cache expired.");
            }
          }
        }
      } else { // write mode
        window.localStorage.setItem(key, encode({
          data: data,
          time: Date.now()
        }));
        return data;
      }
    }
    return getNews(param);
  };

  let loader = memozie(param);

	return { formatDate, getHost, loader };
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-btjmy0-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, []);
	}
}

export default App;